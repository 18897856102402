import * as React from "react"
import { useState, useEffect } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import { useLocation } from "@reach/router"

import * as styles from "src/scss/_form.module.scss"

export default function FormConfrmationPage () {
  const [message, setMsg] = useState("");
  const [pagetitle, setTitle] = useState("");
  const location = useLocation();
  const [url, setURL] = useState("");

  useEffect(() => {
    const link = document.referrer;
    const hostName = location.hostname;
    if (hostName === "www.renk.co.jp") {
      setURL(hostName)
    } else {
      setURL("stg.renk.co.jp")
    }

    if (link.match(/contact/)) {
      setMsg("お問い合わせ")
      setTitle("お問い合わせありがとうございます")
    } else if (link.match(/job-application/) || link.match(/new-grad-job-application/)) {
      setMsg("応募")
      setTitle("応募ありがとうございます")
    } else if (link.match(/infoinvoice-dl/)) {
      setMsg("インフォ・ザ・伝票 仕様版 ダウンロード")
      setTitle("ダウンロードありがとうございます")
    } else {
      setMsg("")
      setTitle("")
    }
  }, []);

  return (
    <Layout>
      <Seo title={pagetitle} />
      <div className="pageWrapper">

        {message === "お問い合わせ" &&
          <div>
            <h2 className={styles.confTtl}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
              お問い合わせの受付を完了いたしました
            </h2>
            <div className={styles.thanksMsg}>
              <p>この度はお問い合わせをいただきましてありがとうございます。</p>
              <p>確認のため、自動返信メールをお送りしております。</p>
              <p>改めて担当よりご連絡をさせていただきますので、<br />
                誠に恐れ入りますが、今しばらくお待ちいただけますようお願いいたします。
              </p>
            </div>
          </div>
        }

        {message === "応募" &&
          <div>
            <h2 className={styles.confTtl}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
              応募の受付を完了いたしました
            </h2>
            <div className={styles.thanksMsg}>
              <p>弊社の求人にご応募いただき、誠にありがとうございます。</p>
              <p>確認のため、自動返信メールをお送りしております。</p>
              <p>今後の選考に関しましては追ってご連絡させていただきますので、<br />
                誠に恐れ入りますが、今しばらくお待ちいただけますようお願いいたします。
              </p>
            </div>
          </div>
        }

        {message === "インフォ・ザ・伝票 仕様版 ダウンロード" &&
          <div>
            <h2 className={styles.confTtl}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
              ダウンロードありがとうございます
            </h2>
            <div className={styles.thanksMsg}>
              <p>下記のボタンをクリックするとファイルのダウンロードが開始されます。</p>
              <div className={styles.dlBtn}>
                <a href={`https://` + url + `/download/InvoiceSetup.zip`}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
                  ダウンロードする
                  <br />
                  <span>InvoiceSteup.exe</span>
                </a>
              </div>
              <p>ダウンロード完了後、デスクトップ画面に ￥InvoiceSetup フォルダーが作成されます。</p>
              <p>セットアップの手順に関しましては、「セットアップガイド.pdf」 をご覧下さい。</p>
              <p>「setup.exe」 を選択してください。インフォ・ザ・伝票のセットアップが開始されます。</p>
            </div>
          </div>
        }

        <div className="btnLarge">
          <a href="/">トップページへ戻る</a>
        </div>

      </div>
    </Layout>
  )
}
